import React, { useState, useRef, useEffect } from 'react';
import { Accordion } from 'react-bootstrap';

// Reusable CustomMonthPicker Component
const CustomMonthPicker = ({ selectedDate, setDate, calendarOpen, setCalendarOpen, minDate }) => {
    const calendarRef = useRef(null);

    // Handle clicking outside the calendar
    const handleClickOutside = (event) => {
        if (calendarRef.current && !calendarRef.current.contains(event.target)) {
            setCalendarOpen(false);
        }
    };

    useEffect(() => {
        if (calendarOpen) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [calendarOpen]);

    // Parse the minDate in MMM-YYYY format
    const parseMinDate = (minDateString) => {
        if (!minDateString) return null;

        const [monthStr, year] = minDateString.split('-');
        const month = new Date(Date.parse(monthStr + " 1, 2000")).getMonth() + 1; // Convert MMM to a month number
        return { month, year: parseInt(year) };
    };

    const parsedMinDate = minDate ? parseMinDate(minDate) : null;

    // Check if a month is before the minDate and should be disabled
    const isDisabled = (year, month) => {
        if (!parsedMinDate) return false;
        const { month: minMonth, year: minYear } = parsedMinDate;

        return year < minYear || (year === minYear && month < minMonth);
    };

    // Handle selecting a month and closing the calendar
    const handleMonthSelect = (e, year, month) => {
        e.preventDefault();
        if (isDisabled(year, month)) return; // Do nothing if the month is disabled

        const selectedDate = `${month < 10 ? `0${month}` : month}-${year}`;
        setDate(selectedDate); // Call the parent setDate function

        // Delay closing the calendar to allow the state to update first
        setTimeout(() => {
            setCalendarOpen(false); // Close the calendar after selection
        }, 0);
    };

    // Create a simple month/year picker
    const renderCalendar = () => {
        const currentYear = new Date().getFullYear();
        const currentMonth = new Date().getMonth() + 1; // getMonth() returns 0-based index, so we add 1
        const years = Array.from({ length: 50 }, (_, i) => currentYear - i).reverse(); // Last 10 years (past)
        const futureYears = Array.from({ length: 50 }, (_, i) => currentYear + i + 1); // Next 10 years (future)
        const months = Array.from({ length: 12 }, (_, i) => i + 1);

        const allYears = [...years, currentYear, ...futureYears]; // Combine past, current, and future years

        return (
            <div ref={calendarRef} className="calendar-popup" style={calendarStyle}>
                <Accordion defaultActiveKey={['0']} alwaysOpen className='w-100'>
                    {allYears.map((year, index) => (
                        <Accordion.Item
                            key={index}
                            eventKey={index.toString()}
                            style={{ width: '300px' }}
                        >
                            <Accordion.Header
                                style={{
                                    width: '100%',
                                    maxWidth: '100%',
                                    flex: '1',  // Ensures full width is utilized
                                }}
                            >
                                <span style={{ color: "#521986", fontWeight: 600 }}>
                                    {year}
                                </span>
                            </Accordion.Header>
                            <Accordion.Body style={{ width: '100%' }}>
                                {months.map((month) => (
                                    <button
                                        key={`${year}-${month}`}
                                        onClick={(e) => handleMonthSelect(e, year, new Date(year, month - 1).toLocaleString('default', {
                                            month: 'short',
                                        }))}
                                        style={{
                                            ...monthButtonStyle,
                                            opacity: isDisabled(year, month) ? 0.5 : 1,
                                            cursor: isDisabled(year, month) ? 'not-allowed' : 'pointer',
                                        }}
                                        disabled={isDisabled(year, month)}
                                    >
                                        {new Date(year, month - 1).toLocaleString('default', {
                                            month: 'short',
                                        })}
                                    </button>
                                ))}
                            </Accordion.Body>
                        </Accordion.Item>
                    ))}
                </Accordion>
            </div>
        );
    };

    return <>{calendarOpen && renderCalendar()}</>;
};

// Styles for the calendar
const calendarStyle = {
    position: 'absolute',
    top: '100%',
    left: 0,
    backgroundColor: '#fff',
    border: '1px solid #ccc',
    padding: '10px',
    zIndex: 1000,
    boxShadow: '0px 4px 8px rgba(0,0,0,0.1)',
    maxHeight: '250px',
    overflowY: 'auto',
    borderRadius: '10px',
};

const monthButtonStyle = {
    backgroundColor: '#f8f9fa',
    border: '1px solid #ccc',
    padding: '5px 10px',
    width: "50px",
    cursor: 'pointer',
    margin: '5px',
};

export default CustomMonthPicker;
