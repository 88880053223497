import React, { useState } from "react";
import "./RT8.css";
import {
  faEnvelope,
  faPhone,
  faMapMarkerAlt,
  faGlobe,
  faGraduationCap,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const RT8non = () => {
  const [fontSize, setFontSize] = useState(0.7);

  const Style = {
    resumeWrapper: {
      width: "100%",
      padding: "15px 8px",
      minHeight: "100%",
      background: "#fff",
      overflow: "auto",
      containerType: "inline-size",
      wordSpacing: "-1px", // Adjusted for reduced word spacing
      color: "#000",
    },
    resumeHead: {
      fontSize: fontSize,
      color: "#33298b",
    },
    title: {
      fontSize: `${fontSize * 5}cqw`,
      marginBottom: `${fontSize * 1}cqw`,
      color: "#33298b",
      fontWeight: "700",
      // textTransform: "uppercase",
    },
    common: {
      fontSize: `${fontSize * 3}cqw`,
      fontFamily: "'Open Sans-Light', Helvetica",
      marginBottom: `${fontSize * 0.5}cqw`,
      wordBreak: "break-word",
      whiteSpace: "break-spaces",
      textAlign: "justify",
      wordSpacing: "-1px", // Adjusted for reduced word spacing
      color: "#000",
      marginRight: "10px",
      padding: "0px 5px",
      lineHeight: "10px",
    },
    profession: {
      color: "#000",
      fontFamily: "'Open Sans-Light', Helvetica",
      fontSize: `${fontSize * 3}cqw`,
      fontWeight: 300,
      textTransform: "uppercase",
    },
    li: {
      fontSize: `${fontSize * 3}cqw`,
      marginBottom: `${fontSize * 0}cqw`,
    },
    headings: {
      fontfamily: "'Open Sans', Helvetica",
      fontSize: `${fontSize * 4}cqw`,
      color: "#33298b",
      fontWeight: "700",
      marginBottom: `${fontSize * 1}cqw`,
      marginRight: `15px`,
    },
    subHeadings: {
      fontSize: `${fontSize * 4}cqw`,
      fontWeight: "700",
      marginBottom: `${fontSize * 1}cqw`,
      color: "#000",
      padding: "0px 5px",
    },
    subHeading: {
      fontSize: `${fontSize * 3}cqw`,
      fontWeight: "700",
      marginBottom: `${fontSize * 1}cqw`,
      color: "#000",
      padding: "0px 5px",
    },
    eduheads: {
      fontSize: `${fontSize * 4}cqw`,
      fontWeight: "700",
      marginBottom: `${fontSize * 1}cqw`,
      color: "#000",
      padding: "0px 5px",
    },
    marginHR: {
      margin: `${fontSize * 2}cqw 0`,
      color: "#33298b",
      borderTop: "3px solid",
      opacity: 1.25,
    },
    marginUl: {
      margin: `${fontSize * 0}cqw 0`,
      display: "flex",
      listStyletype: "none",
      flexDirection: "row",
      flexWrap: "wrap",
      padding: "0",
    },
    horizontallist: {
      display: "flex",
      listStyletype: "none",
      margin: 0,
      padding: 0,
    },
    marker: {
      margin: 0,
    },
    ul: {
      margin: 0,
      paddingLeft: "15px",
    },
    img: {
      paddingBottom: "5px",
      width: "20%",
      objectFit: "contain",
    },
    logo: {
      width: "20%",
      objectFit: "contain",
    },
    table: {
      width: "100%",
      margin: "10px 0px",
      borderCollapse: "collapse",
      fontSize: `${fontSize * 3}cqw`,
      fontFamily: "'Open Sans-Light', Helvetica",
    },
    tableRow: {
      display: "flex",
      justifyContent: "space-between",
      marginBottom: "5px",
      borderBottom: "1px solid #ccc", // Border for each row
      fontSize: `${fontSize * 3}cqw`,
      fontFamily: "'Open Sans-Light', Helvetica",
    },
    tableCell: {
      width: "30%",
      borderRight: "1px solid #ccc", // Border for each cell
      padding: "0px 2px",
    },
    section: {
      paddingBottom: "5px",
    },
  };

  return (
    <div style={Style.resumeWrapper}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          paddingBottom: "5px",
        }}
      >
        <div>
          <img
            src="https://viosainterviewvideos.s3.ap-south-1.amazonaws.com/interviewvideos/Ashoka+logo.png"
            alt="profile"
            style={Style.logo}
          />
          <h1 style={Style.title}>Ajay Shah</h1>
          <div style={Style.contactInfo}>
            <p style={Style.common}>
              <FontAwesomeIcon icon={faGraduationCap} /> MBA [Major: Marketing,
              Minor: Digital Marketing]
            </p>
            <p style={Style.common}>
              <FontAwesomeIcon icon={faPhone} /> +91 98765 54321
            </p>
            <p style={Style.common}>
              <FontAwesomeIcon icon={faEnvelope} /> anita@gmail.com
            </p>
            <p style={Style.common}>
              <FontAwesomeIcon icon={faGlobe} /> linkedin.com/in/anita-sharma
            </p>
          </div>
        </div>
        <img
          src="https://viosa-storage-2.s3.amazonaws.com/profile.png"
          alt="profile"
          style={Style.img}
        />
      </div>

      <div style={Style.section}>
        <h2 style={Style.headings}>Professional Summary</h2>
        <div
          style={{
            flex: 1,
            backgroundColor: "#33298b",
            height: "2px",
            marginBottom: "3px",
          }}
        />
        <p style={Style.common}>
          A dedicated and ambitious MBA candidate with a focus on Marketing and
          a minor in Digital Marketing. Skilled in digital advertising, content
          marketing, and SEO. Excellent communication and leadership skills with
          the ability to work in collaborative, fast-paced environments.
        </p>
      </div>

      <div style={Style.section}>
        <h2 style={Style.headings}>Education</h2>
        <div
          style={{
            flex: 1,
            backgroundColor: "#33298b",
            marginBottom: "3px",
            height: "2px",
          }}
        />
        <div style={Style.table}>
          <div style={Style.tableRow}>
            <div style={Style.tableCell}>MBA</div>
            <div style={Style.tableCell}>Ashoka Centre</div>
            <div style={Style.tableCell}>Nashik</div>
            <div style={Style.tableCell}>Jun 2025 (Expected)</div>
          </div>
          <div style={Style.tableRow}>
            <div style={Style.tableCell}>BMM</div>
            <div style={Style.tableCell}>Don Bosco College</div>
            <div style={Style.tableCell}>Mumbai</div>
            <div style={Style.tableCell}>Apr 2023</div>
          </div>
          {/* Add more rows as needed */}
        </div>
      </div>

      <div style={Style.section}>
        <h2 style={Style.headings}>Skills</h2>
        <div
          style={{
            flex: 1,
            backgroundColor: "#33298b",
            marginBottom: "3px",
            height: "2px",
          }}
        />
        <p style={Style.common}>
          Data Analytics | Effective Communication | Market Research | Multi
          Tasking | Time Management
        </p>
      </div>

      <div style={Style.section}>
        <h2 style={Style.headings}>Certificates</h2>
        <div
          style={{
            flex: 1,
            backgroundColor: "#33298b",
            marginBottom: "3px",
            height: "2px",
          }}
        />
        <div style={Style.section}>
          <div class="yui-u d-flex justify-content-between">
            <h2 style={Style.eduheads}>Full Stack Developer</h2>
            <p style={Style.common}>2021-06-16</p>
          </div>
          <div class="yui-u">
            <p style={Style.common}>SimpliLearn</p>
          </div>
        </div>
        <div style={Style.section}>
          <div class="yui-u d-flex justify-content-between">
            <h2 style={Style.eduheads}>Full Stack Developer</h2>
            <p style={Style.common}>2021-06-16</p>
          </div>
          <div class="yui-u">
            <p style={Style.common}>SimpliLearn</p>
          </div>
        </div>
      </div>

      <div style={Style.section}>
        <h2 style={Style.headings}>
          <h2 style={Style.headings}>Projects</h2>
        </h2>
        <div
          style={{
            flex: 1,
            backgroundColor: "#33298b",
            marginBottom: "3px",
            height: "2px",
          }}
        />
        <div style={Style.section}>
          <div class="yui-u d-flex justify-content-between">
            <h2 style={Style.eduheads}>
              Research Paper: Impact of Social Media Marketing on Consumer
              Behavior
            </h2>
            <p style={Style.common}>2021-06-16</p>
          </div>
          <div class="yui-u">
            <p style={Style.common}>SimpliLearn</p>
          </div>
        </div>
        <div style={Style.section}>
          <div class="yui-u d-flex justify-content-between">
            <h2 style={Style.eduheads}>Full Stack Developer</h2>
            <p style={Style.common}>2021-06-16</p>
          </div>
          <div class="yui-u">
            <p style={Style.common}>SimpliLearn</p>
          </div>
        </div>
      </div>

      <div class="yui-gf mb-2">
        <h2 style={Style.headings}>Experience</h2>
        <div
          style={{
            flex: 1,
            backgroundColor: "#33298b",
            marginBottom: "3px",
            height: "2px",
          }}
        />
        <div class="yui-u ">
          <div class="job">
            <div className="d-flex justify-content-between">
              <p style={Style.eduheads}>Senior Interface Designer</p>
              <p style={Style.common}>May 2005 - June 2007</p>
            </div>
            <h2 style={Style?.subHeading}>
              Facebook , <small>California</small>
            </h2>
            <p style={Style.common}>
              Intrinsicly enable optimal core competencies through corporate
              relationships. Phosfluorescently implement worldwide vortals and
              client-focused imperatives.{" "}
            </p>
          </div>
          <div className="d-flex justify-content-between">
            <p style={Style.eduheads}>Senior Interface Designer</p>
            <p style={Style.common}>May 2005 - June 2007</p>
          </div>
          <h2 style={Style?.subHeading}>
            Facebook , <small>California</small>
          </h2>
          <p style={Style.common}>
            Intrinsicly enable optimal core competencies through corporate
            relationships. Phosfluorescently implement worldwide vortals and
            client-focused imperatives.{" "}
          </p>
        </div>
      </div>

      <div style={Style.section}>
        <h2 style={Style.headings}>Languages Known</h2>
        <div
          style={{
            flex: 1,
            backgroundColor: "#33298b",
            marginBottom: "3px",
            height: "2px",
          }}
        />
        <p style={Style.common}>English | Hindi | Marathi | French</p>
      </div>
    </div>
  );
};

export default RT8non;
