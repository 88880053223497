import CustomMonthPicker from "components/common/Calender";
import {
  UpdateAchievementsFields,
  setAchievementsFields,
} from "helper/redux/Slice/ResumeSlice";
import React, { useState, useEffect } from "react";
import { FloatingLabel, Form } from "react-bootstrap";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";

function Achievement({
  UpdateRedux,
  setUpdateRedux,
  handleResponsivePreviewShow,
}) {
  const dispatch = useDispatch();
  const AchievementRedux = useSelector((item) => item.resume.achievements);
  const [AchievementsObj, setAchievementsObj] = useState({
    index: AchievementRedux?.length,
    title: "",
    date: "",
    description: "",
  });
  console.log(AchievementsObj);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setAchievementsObj({ ...AchievementsObj, [name]: value });
  };

  const handleSubmitToRedux = (e) => {
    e.preventDefault();
    if (!AchievementsObj?.date) {
      toast.error("Please enter a date.");
      return;
    }
    if (!!UpdateRedux) {
      dispatch(
        UpdateAchievementsFields(AchievementsObj, AchievementsObj?.index)
      );
      setUpdateRedux(null);
      toast.success("Achievement Updated Successfully");
    } else {
      if (!!AchievementsObj?.title) {
        dispatch(setAchievementsFields(AchievementsObj));
        toast.success("Achievement Saved Successfully");
      } else {
        return;
      }
    }
  };

  useEffect(() => {
    if (!!UpdateRedux?.title) {
      setAchievementsObj(UpdateRedux);
    }
  }, [UpdateRedux]);

  const [isStartCalendarOpen, setIsStartCalendarOpen] = useState(false);
  const handleStartDate = (sdate) => {
    setAchievementsObj({ ...AchievementsObj, date: sdate })
    setIsStartCalendarOpen(false)
  }
  return (
    <>
      <Form className="form-main">
        <div className="d-flex gap-3 justify-content-between">
          <FloatingLabel
            controlId="floatingInput"
            label={<span className="required-label">Title</span>}
            className="mb-3 w-100"
          >
            <Form.Control
              type="text"
              value={AchievementsObj?.title}
              name="title"
              required
              onChange={handleChange}
              placeholder="Title"
            />
          </FloatingLabel>
          <FloatingLabel
            controlId="floatingStartDate"
            label="Date of certificate/workshop?"
            className="mb-3 w-100"
            onClick={() => setIsStartCalendarOpen(true)}
          >
            <Form.Control
              type="text"
              value={AchievementsObj?.date ? AchievementsObj?.date : 'MM-YYYY'}
              readOnly
              placeholder="When did you join the company?"
              style={{ paddingRight: '40px' }}
            />
            <div style={{ position: 'relative' }}>
              <CustomMonthPicker
                selectedDate={AchievementsObj?.date}
                setDate={handleStartDate}
                calendarOpen={isStartCalendarOpen}
                setCalendarOpen={setIsStartCalendarOpen}
              />
            </div>
          </FloatingLabel>
        </div>
        <FloatingLabel
          controlId="floatingInput"
          label="Add short description"
          className="mb-3"
        >
          <Form.Control
            type="text"
            value={AchievementsObj?.description}
            name="description"
            onChange={handleChange}
            placeholder="description"
          />
        </FloatingLabel>
        <button className="save-experience" onClick={handleSubmitToRedux}>
          Save To Achievements
        </button>
      </Form>
    </>
  );
}

export default Achievement;
