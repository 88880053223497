import { SetAnalysis, SetAnalysisResume } from "helper/redux/Slice/ResumeSlice";
import React, { useState, useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import { BiCircle } from "react-icons/bi";
import { BsCircleFill } from "react-icons/bs";
import { IoMdInformationCircleOutline } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import UseOptimization from "./Hooks/useOptimization";
import UseCompleteness from "./Hooks/useCompleteness";
import { SetAnalysisCover } from "helper/redux/Slice/CoverSlice";
import UseATScompliance from "./Hooks/useATScompliance";
import { useParams } from "react-router";
import UseContactInfo from "./Hooks/useContactInfo";

const AnalysisFeature = ({ data, customizeWidth }) => {
  console.log(data);
  const types = useSelector((item) => item.types);
  const AnalysisKey = [
    "Completeness",
    "Contact Info",
    "Strong Summary",
    "ATS Compliance",
    // "Length",
    "Optimization",
  ];
  let ResumeRedux = useSelector((item) => item?.resume);
  let CoverRedux = useSelector((item) => item?.cover);
  if (!!data) {
    if (types?.doctype === "resume") {
      // ResumeRedux = data;
    } else {
      // CoverRedux = data
    }
  }
  const [showInfo, setshowInfo] = useState(false);
  const [InfoType, setInfoType] = useState();

  const handleInfoModalClose = () => {
    setshowInfo(false);
  };
  const handleInfoModalShow = () => {
    setshowInfo(true);
  };

  // for optimization
  const dispatch = useDispatch();
  const optimizationvValue = UseOptimization("");
  const CompletenessValue = UseCompleteness("");
  let ATSValue = UseATScompliance("");
  let contactValue = UseContactInfo("");

  const utils = useSelector((item) => item.utils);
  const param = useParams();

  useEffect(() => {
    if (types?.doctype === "resume") {
      dispatch(
        SetAnalysisResume({ type: "optimization", value: optimizationvValue })
      );
      dispatch(
        SetAnalysisResume({ type: "completeness", value: CompletenessValue })
      );
      dispatch(
        SetAnalysisResume({ type: "clearContactInfo", value: contactValue })
      );
      if (!!ResumeRedux?.profession) {
        dispatch(SetAnalysisResume({ type: "atsCompliance", value: ATSValue }));
      }
    } else if (types?.doctype === "cover" && !param?.id) {
      dispatch(
        SetAnalysisCover({ type: "clearContactInfo", value: contactValue })
      );
      dispatch(
        SetAnalysisCover({ type: "optimization", value: optimizationvValue })
      );
      dispatch(
        SetAnalysisCover({ type: "completeness", value: CompletenessValue })
      );
    }
  }, []);
  // Filter out "ATS Compliance" if no profession data is available
  const filteredAnalysisKey = ResumeRedux?.profession
    ? [
      "Completeness",
      "Contact Info",
      "Strong Summary",
      "ATS Compliance",
      // "Length",
      "Optimization",
    ]
    : [
      "Completeness",
      "Contact Info",
      "Strong Summary",
      // "Length",
      "Optimization",
    ];

  return (
    <>
      <>
        {filteredAnalysisKey?.map((val, index) => {
          return (
            <div
              key={index}
              style={{ width: `${!!customizeWidth ? customizeWidth : "45%"}` }}
            >
              <div className="analytics-card position-relative bg-white rounded my-1 mt-0">
                <span
                  className="info-icon cursor-pointer"
                  onClick={() => {
                    setInfoType(val);
                    handleInfoModalShow();
                  }}
                >
                  <IoMdInformationCircleOutline fontSize={20} />
                </span>
                <CircularProgressbar
                  styles={buildStyles({
                    pathColor: `#5cd69c`,
                    textColor: "#9671b6",
                    trailColor: "#d6d6d6",
                    width: "50px",
                    fontSize: "0.9rem",
                    backgroundColor: "#3e98c7",
                  })}
                  value={
                    types?.doctype === "cover"
                      ? val === "Completeness"
                        ? CoverRedux?.completeness ?? 0
                        : val === "Contact Info"
                          ? !isNaN(Math.floor(ResumeRedux?.clearContactInfo))
                            ? Math.floor(ResumeRedux?.clearContactInfo)
                            : 0 ?? 0
                          : val === "Formatting"
                            ? CoverRedux?.formatting
                            : val === "Length"
                              ? CoverRedux?.length
                              : val === "Clear Contact Info"
                                ? CoverRedux?.clearContactInfo
                                : val === "ATS Compliance"
                                  ? CoverRedux?.atsCompliance ?? 0
                                  : val === "Strong Summary"
                                    ? CoverRedux?.strongSummary
                                    : val === "Optimization"
                                      ? CoverRedux?.optimization
                                      : 0
                      : val === "Completeness"
                        ? ResumeRedux?.completeness
                        : val === "Formatting"
                          ? ResumeRedux?.formatting
                          : val === "Contact Info"
                            ? ResumeRedux?.clearContactInfo ?? 0
                            : val === "Length"
                              ? ResumeRedux?.length
                              : val === "ATS Compliance"
                                ? ResumeRedux?.atsCompliance
                                : val === "Strong Summary"
                                  ? ResumeRedux?.strongSummary
                                  : val === "Optimization"
                                    ? ResumeRedux?.optimization
                                    : 0
                  }
                  text={`${types.doctype === "resume"
                    ? val === "Completeness"
                      ? Math.floor(ResumeRedux?.completeness) ?? 0
                      : val === "Contact Info"
                        ? !isNaN(Math.floor(ResumeRedux?.clearContactInfo))
                          ? Math.floor(ResumeRedux?.clearContactInfo)
                          : 0 ?? 0
                        : val === "ATS Compliance"
                          ? !isNaN(Math.floor(ResumeRedux?.atsCompliance))
                            ? Math.floor(ResumeRedux?.atsCompliance)
                            : 0 ?? 0
                          : val === "Strong Summary"
                            ? ResumeRedux?.strongSummary ?? 0
                            : val === "Optimization"
                              ? !isNaN(Math.floor(ResumeRedux?.optimization))
                                ? Math.floor(ResumeRedux?.optimization)
                                : 0 ?? 0
                              : val === "Length"
                                ? ResumeRedux?.length ?? 0
                                : 0
                    : val === "Completeness"
                      ? Math.floor(CoverRedux?.completeness) ?? 0
                      : val === "Formatting"
                        ? CoverRedux?.formatting ?? 0
                        : val === "Contact Info"
                          ? !isNaN(Math.floor(CoverRedux?.clearContactInfo))
                            ? Math.floor(CoverRedux?.clearContactInfo)
                            : 0 ?? 0
                          : val === "Length"
                            ? CoverRedux?.length ?? 0
                            : val === "ATS Compliance"
                              ? CoverRedux?.atsCompliance ?? 0
                              : val === "Strong Summary"
                                ? CoverRedux?.strongSummary ?? 0
                                : val === "Optimization"
                                  ? !isNaN(Math.floor(CoverRedux?.optimization))
                                    ? Math.floor(CoverRedux?.optimization)
                                    : 0 ?? 0
                                  : 0
                    }%`}
                />

                <p className="text-center">{val}</p>
              </div>
            </div>
          );
        })}
      </>
      <InformationModal
        showInfo={showInfo}
        Resume={ResumeRedux}
        InfoType={InfoType}
        handleInfoModalClose={handleInfoModalClose}
      />
    </>
  );
};

export default AnalysisFeature;

const InformationModal = ({
  handleInfoModalClose,
  showInfo,
  InfoType,
  Resume,
}) => {
  const types = useSelector((item) => item.types);
  // const Resume = useSelector(item => item.resume)
  const Cover = useSelector((item) => item.cover);
  const Utils = useSelector((item) => item.utils);
  return (
    <>
      <Modal
        scrollable
        centered
        show={showInfo}
        onHide={handleInfoModalClose}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>{InfoType} Suggestion</Modal.Title>
        </Modal.Header>
        <Modal.Body className="optimization-modal">
          {InfoType === "Optimization" ? (
            <>
              {/* optimization for resume */}
              {types?.doctype === "resume" ? (
                <>
                  {Resume?.experiences?.length < 3 && (
                    <div className="mb-3">
                      <h5>Experiences</h5>
                      <p>
                        <BsCircleFill /> Add atleast 3 Experiences with
                        description , you've added only{" "}
                        {Resume?.experiences?.length} Experiences
                      </p>
                    </div>
                  )}
                  {Resume?.projects?.length < 2 && (
                    <div className="mb-3">
                      <h5>Project</h5>
                      <p>
                        <BsCircleFill />
                        Add atleast 2 Projects with description ,, you've added
                        only {Resume?.projects?.length} Projects{" "}
                      </p>
                    </div>
                  )}
                  {Resume.education?.length < 2 && (
                    <div className="mb-3">
                      <h5>Education</h5>
                      <p>
                        <BsCircleFill />
                        Add atleast 3 Education with description , you've added
                        only {Resume?.education?.length} Education{" "}
                      </p>
                    </div>
                  )}

                  {Resume?.certification?.length < 2 && (
                    <div className="mb-3">
                      <h5>Certificate</h5>
                      <p>
                        <BsCircleFill />
                        Add atleast 2 Certificate with description , you've
                        added only {
                          Resume?.certification?.length
                        } Ceritificate{" "}
                      </p>
                    </div>
                  )}
                  {Resume?.involvement?.length < 2 && (
                    <div className="mb-3">
                      <h5>Voluntary</h5>
                      <p>
                        <BsCircleFill />
                        Add atleast 2 Voluntary with description , you've added
                        only {Resume?.involvement?.length} Voluntary{" "}
                      </p>
                    </div>
                  )}
                  {Resume?.skills?.length < 2 && (
                    <div className="mb-3">
                      <h5>Skills</h5>
                      <p>
                        <BsCircleFill />
                        Add atleast 3 Skills , you've added only{" "}
                        {Resume?.skills?.length} Skills{" "}
                      </p>
                    </div>
                  )}
                  {Resume?.hobby?.length < 2 && (
                    <div className="mb-3">
                      <h5>Hobby</h5>
                      <p>
                        <BsCircleFill />
                        Add atleast 3 Hobbies , you've added only{" "}
                        {Resume?.hobby?.length} Hobby{" "}
                      </p>
                    </div>
                  )}
                </>
              ) : (
                <>
                  {/* optimization for cover letter */}

                  {Cover?.experiences?.length < 3 && (
                    <div className="mb-3">
                      <h5>Experiences</h5>
                      <p>
                        <BsCircleFill /> Add atleast 3 Experiences with
                        description , you've added only{" "}
                        {Cover?.experiences?.length} Experiences
                      </p>
                    </div>
                  )}
                  {Cover?.projects?.length < 2 && (
                    <div className="mb-3">
                      <h5>Project</h5>
                      <p>
                        <BsCircleFill />
                        Add atleast 2 Experiences with description ,, you've
                        added only {Cover?.projects?.length} Projects{" "}
                      </p>
                    </div>
                  )}
                  {Cover?.education?.length < 2 && (
                    <div className="mb-3">
                      <h5>Education</h5>
                      <p>
                        <BsCircleFill />
                        Add atleast 3 Education with description , you've added
                        only {Cover?.education?.length} Education{" "}
                      </p>
                    </div>
                  )}

                  {Cover?.certification?.length < 2 && (
                    <div className="mb-3">
                      <h5>Certificate</h5>
                      <p>
                        <BsCircleFill />
                        Add atleast 2 Certificate with description , you've
                        added only {
                          Cover?.certification?.length
                        } Ceritificate{" "}
                      </p>
                    </div>
                  )}
                  {Cover?.involvement?.length < 2 && (
                    <div className="mb-3">
                      <h5>Voluntary</h5>
                      <p>
                        <BsCircleFill />
                        Add atleast 2 Voluntary with description , you've added
                        only {Cover?.involvement?.length} Voluntary{" "}
                      </p>
                    </div>
                  )}
                </>
              )}
            </>
          ) : InfoType === "Completeness" ? (
            <>
              {/* completeness for resume */}

              {types?.doctype === "resume" ? (
                <>
                  {Resume?.involvement?.length === 0 && (
                    <div className="mb-3">
                      <h5>Involvement</h5>
                      <p>
                        <BsCircleFill />
                        Add a Voluntary{" "}
                      </p>
                    </div>
                  )}
                  {Resume?.skills?.length === 0 && (
                    <div className="mb-3">
                      <h5>Skills</h5>
                      <p>
                        <BsCircleFill />
                        Add a Skill{" "}
                      </p>
                    </div>
                  )}
                  {Resume?.hobby?.length === 0 && (
                    <div className="mb-3">
                      <h5>Hobby</h5>
                      <p>
                        <BsCircleFill />
                        Add a Hobby{" "}
                      </p>
                    </div>
                  )}
                  {Resume?.project?.length === 0 && (
                    <div className="mb-3">
                      <h5>Project</h5>
                      <p>
                        <BsCircleFill />
                        Add a Project{" "}
                      </p>
                    </div>
                  )}
                  {Resume?.education?.length === 0 && (
                    <div className="mb-3">
                      <h5>Education</h5>
                      <p>
                        <BsCircleFill /> Add a Education{" "}
                      </p>
                    </div>
                  )}
                  {Resume?.experiences?.length === 0 && (
                    <div className="mb-3">
                      <h5>Experience</h5>
                      <p>
                        <BsCircleFill />
                        Add a Experience{" "}
                      </p>
                    </div>
                  )}
                  {Resume?.certification?.length === 0 && (
                    <div className="mb-3">
                      <h5>Certificate</h5>
                      <p>
                        <BsCircleFill />
                        Add a Certificate{" "}
                      </p>
                    </div>
                  )}
                  {!Resume?.summary && (
                    <div className="mb-3">
                      <h5>Summary</h5>
                      <p>
                        <BsCircleFill />
                        Add Summary{" "}
                      </p>
                    </div>
                  )}
                </>
              ) : (
                <>
                  {Resume?.skills?.length === 0 && (
                    <div className="mb-3">
                      <h5>Skills</h5>
                      <p>
                        <BsCircleFill />
                        Add a Skill{" "}
                      </p>
                    </div>
                  )}
                  {Resume?.project?.length === 0 && (
                    <div className="mb-3">
                      <h5>Project</h5>
                      <p>
                        <BsCircleFill />
                        Add a Project{" "}
                      </p>
                    </div>
                  )}
                  {Resume?.education?.length === 0 && (
                    <div className="mb-3">
                      <h5>Education</h5>
                      <p>
                        <BsCircleFill /> Add a Education{" "}
                      </p>
                    </div>
                  )}
                  {Resume?.experiences?.length === 0 && (
                    <div className="mb-3">
                      <h5>Experience</h5>
                      <p>
                        <BsCircleFill />
                        Add a Experience{" "}
                      </p>
                    </div>
                  )}
                  {Resume?.certification?.length === 0 && (
                    <div className="mb-3">
                      <h5>Certificate</h5>
                      <p>
                        <BsCircleFill />
                        Add a Certificate{" "}
                      </p>
                    </div>
                  )}
                </>
              )}
            </>
          ) : InfoType === "Length" ? (
            <>
              {types?.doctype === "resume" ? (
                <div className="mb-3">
                  <h5>Length</h5>
                  <p>
                    <BsCircleFill /> Your Resume's Length is{" "}
                    {Resume?.length === 100
                      ? "Good"
                      : Resume?.length === 75
                        ? "Could be better"
                        : "Needs to be better"}{" "}
                  </p>
                </div>
              ) : (
                <>
                  {
                    <div className="mb-3">
                      <h5>Length</h5>
                      <p>
                        <BsCircleFill />
                        Your Cover Letter's Length is{" "}
                        {Cover?.length === 100
                          ? "Good"
                          : Cover?.length === 75
                            ? "Could be better"
                            : "Needs to be better"}{" "}
                      </p>
                    </div>
                  }
                </>
              )}
            </>
          ) : InfoType === "ATS Compliance" ? (
            <>
              {!!Resume?.profession ? (
                <>
                  {" "}
                  <p>
                    <BsCircleFill /> Your ATS Score is {Resume?.atsCompliance}
                  </p>
                  <p className="mt-4">
                    You can use these words in your resume to improve the ATS
                    compliance Score :-{" "}
                  </p>
                  <div className="px-2">
                    <strong>
                      {Utils?.jobSpecificSkill?.map((item) => item + ", ")}
                    </strong>
                  </div>
                </>
              ) : (
                `There is no Compliance score for the generic resume`
              )}
            </>
          ) : (
            <>
              {InfoType === "Strong Summary" && (
                <>
                  <p style={{ wordBreak: "break-all" }}>
                    {" "}
                    Your Profile summary is :-
                    {Resume?.summary}
                  </p>
                  <div className="mt-3">
                    {/* <button className='px-3 improvise-summary-btn  p-2 rounded'>Improvise Summmary ( 2 AI Credits )</button> */}
                  </div>
                </>
              )}
            </>
          )}

          {/* <h5>Summary</h5>
        <p><BsCircleFill /> Lorem ipsum dolor sit amet consectetur adipisicing elit. Sit eum unde reiciendis exercitationem  </p>
        <p><BsCircleFill /> Lorem ipsum dolor sit amet consectetur adipisicing elit. Sit eum unde reiciendis exercitationem </p>
        <p><BsCircleFill /> Lorem ipsum dolor sit amet consectetur adipisicing elit. Sit eum unde reiciendis exercitationem </p> */}
        </Modal.Body>
      </Modal>
    </>
  );
};
