import { AiButton } from "components/AiButton/AiButton";
import { ChatGPTAPI, CutAiCredits } from "helper/api";
import {
  GettingResumeStrongSummary,
  setSummaryFields,
} from "helper/redux/Slice/ResumeSlice";
import { setAiCredits, setdisableNext } from "helper/redux/Slice/UtilsSlice";
import React, { useState, useEffect } from "react";
import { Form, FloatingLabel, Spinner } from "react-bootstrap";
import { toast } from "react-hot-toast";
import { useSelector, useDispatch } from "react-redux";
import GptModal from "./GptModal";

export const Summary = ({ handleResponsivePreviewShow, StepNextBtn }) => {
  const dispatch = useDispatch();
  // const summary = useSelector((item) => item.resume.summary)
  const Resume = useSelector((item) => item.resume);
  const [loadSpinner, setloadSpinner] = useState(false);
  const [GptData, setGptData] = useState();
  const [DisableAiBtn, setDisableAiBtn] = useState(true);
  const [summary, setsummary] = useState(null);
  const UserData = useSelector((item) => item?.user?.user);
  const EducationData = useSelector((item) => item.resume.education);
  const [recentDegree, setRecentDegree] = useState("");
  const [recentDegreeyear, setRecentDegreeyear] = useState("");
  const [PremiumFeature, setPremiumFeature] = useState(false);

  const handlePremiumFeatureClose = () => {
    setPremiumFeature(false);
  };

  useEffect(() => {
    if (EducationData?.length > 0) {
      // Create a copy of EducationData before sorting
      const sortedEducationData = [...EducationData];

      // Sort the copied array based on completion dates in descending order
      sortedEducationData.sort((a, b) => {
        const dateA = new Date(a.date);
        const dateB = new Date(b.date);
        return dateB - dateA;
      });

      // Get the most recent degree
      const mostRecentDegree = sortedEducationData[0]?.degree;
      console.log("Most recent degree fetched:", mostRecentDegree);
      setRecentDegree(mostRecentDegree);
      const mostRecentDegreeyear = sortedEducationData[0]?.date;
      console.log("Most recent degree fetched:", mostRecentDegreeyear);
      setRecentDegreeyear(mostRecentDegreeyear);
    }
  }, [EducationData]);
  const [EducationObj, setEducationObj] = useState({
    id: EducationData?.length,
    status: "",
    degree: "",
    specialization: "",
    institute: "",
    location: "",
    date: "",
    minor: "",
    gpa: "",
    percentage: "",
  });

  const PostChatGPTData = async () => {
    if (UserData?.aicredits < 3) {
      setPremiumFeature(true);
      return;
    }
    setloadSpinner(true);
    let Experience = "";
    let expMonth = 0;
    Resume?.experiences?.map((item) => (Experience += item?.role + " ,"));
    Resume?.experiences?.map((item) => (expMonth += item?.totalexperience));
    let Education = "";
    Resume?.education?.map((item) => (Education += item?.degree + ", "));
    let skills = "";
    Resume?.skills?.map((item) => (skills += item?.name + ", "));
    const profession = Resume?.profession;
    let firstName = Resume?.contact?.firstName;
    let lastName = Resume?.contact?.lastName;
    let certification = "";
    Resume?.certification?.map((item) => (certification += item?.name + ","));
    const temperature = Math.random() * (0.4 - 0.1) + 0.1;
    // Get current year
    const currentYear = new Date().getFullYear();
    // Check if the user is a fresher (no work experience)
    const isFresher = !Resume?.experiences?.length || expMonth === 0;

    // Create the appropriate prompt based on work experience
    const prompt = isFresher
      ? `You will act as an expert in resume parsing for an Applicant Tracking System (ATS) to generate a concise, 70-word summary for a fresher applying for the role of ${profession}. The summary should be based on the following resume details:

      Personal Information: ${firstName}, ${lastName}
      Education: ${Education}
      Recent Degree: ${recentDegree}
      Recent Degree Year: ${recentDegreeyear}
      Skills: ${skills}
      Certifications: ${certification}
      If the Recent Degree Year (${recentDegreeyear}) is still in progress or is dated in the future compared to the current year (${currentYear}), use the present tense. Ensure that the summary highlights academic achievements, skills, and certifications relevant to the profession applied for (${profession}). The summary should be ATS-compliant, concise, grammatically correct, and should not use any pronouns.

      Example summary:

      Aspiring Management Trainee with a keen interest in organizational management. Eager to apply academic knowledge in real-world scenarios. Strong analytical, problem-solving, and communication skills combined with a proactive attitude and a willingness to learn and grow within a dynamic and fast-paced environment.`
      : `Imagine you're developing a resume parsing algorithm for an Applicant Tracking System (ATS) to evaluate candidates based on the following criteria:
          1. Work Experience
          2. Education
          3. Skills
          4. Certifications
          5. Achievements

          You've been provided with a sample resume in the following format:
          1. Personal Information: ${firstName}, ${lastName}
          2. Work Experience: ${Experience}
          3. Education: ${Education} 
          4. Recent Degree: ${recentDegree}
          5. Recent Degree Year: ${recentDegreeyear}
          6. Skills: ${skills}
          7. Certifications: ${certification}
          8. Profession user wants to apply for: ${profession}

          Your task is to parse the resume and generate an ATS-compliant summary in 70 words for the role of ${profession}. If there is no work experience, generate a summary for a fresher. If the Recent Degree Year (${recentDegreeyear}) is still in the future or is being pursued, write the summary in the present tense; otherwise, use the past tense. Ensure it's concise and grammatically correct. No pronouns should be used.
          Example summary:

      Experienced Management Trainee with expertise in applying leadership and technical skills in dynamic business environments. Skilled in data analysis, problem-solving, and strategic planning. Proven ability to lead cross-functional teams and deliver business growth through innovative solutions. Committed to continuous learning and professional development.`;

    const payload = {
      question: prompt,
      temperature: temperature.toFixed(1),
    };
    // let payload = {};
    // ${JSON.stringify(EducationObj)}
    // payload = {
    //   // question: `Write an ATS-compliant summary for the resume of a college student pursue a ${recentDegree}. Emphasize their academic pursuits, relevant skills, and enthusiasm for gaining real-world experience. Ensure it's concise, grammatically correct, and within 70 words. No pronoun should be used.`,
    //   question: `Write an ATS-compliant summary for the resume add and use following details ${recentDegree} for the profession ${profession}. Emphasize my academic pursuits, relevant skills ${skills}, and enthusiasm for gaining real-world experience. Ensure it's concise, grammatically correct, and within 70 words. No pronoun should be used.`,
    //   temperature: temperature.toFixed(1), // Limit to one decimal place
    // };
    //     payload = {
    //       // question: `Write an ATS-compliant summary for the resume of a college student pursue a ${recentDegree}. Emphasize their academic pursuits, relevant skills, and enthusiasm for gaining real-world experience. Ensure it's concise, grammatically correct, and within 70 words. No pronoun should be used.`,
    //       question: `Imagine you're developing a resume parsing algorithm for an Applicant Tracking System (ATS) to evaluate candidates based on the following criteria:
    //       1. Work Experience
    //       2. Education
    //       3. Skills
    //       4. Certifications
    //       5. Achievements

    //       You've been provided with a sample resume in format containing the following fields:
    //       1. Personal Information: ${firstName}, ${lastName}
    //       2. Work Experience: ${Experience}
    //       3. Education: ${Education}
    //       4. Recent Degree - ${recentDegree}
    //       5. Recent Degree Year - ${recentDegreeyear}
    //       5. Skills: ${skills}
    //       6. Certifications: ${certification}
    //       7. Profession user want to apply for: ${profession}

    //       Your task is to parse the resume and make the ATS Compliant Summary in 70 words only for the role - ${profession}. If there is no work experience then generate summary for a fresher. And see if the Recent Degree Year ${recentDegreeyear} is still in pursing or is in future from current year ${currentYear} then make answer summary in present tense otherwise make it in past tense. Ensure it's concise & grammatically correct. No pronouns should be used.

    //       Below are the Examples of a summary of how you should give the summary with no pronouns to be used:
    // - Highly motivated Management Trainee with a strong foundation in business administration seeking to leverage academic achievements and internship experience to contribute effectively to organizational goals. Proficient in strategic planning, problem-solving, and team collaboration. Demonstrated leadership potential through involvement in extracurricular activities. Eager to apply knowledge and skills in a dynamic corporate environment to drive innovation and growth.

    // - Experienced Management Trainee adept at learning and applying leadership principles in dynamic environments. Possessing a strong foundation in organizational management, strategic planning, and team collaboration. Skilled in analyzing data, problem-solving, and implementing innovative solutions to drive business growth. Excellent communication and interpersonal skills, coupled with a proactive attitude and a passion for continuous learning and development. Committed to contributing effectively to organizational success.

    // - Aspiring Management Trainee with a keen interest in developing leadership skills and gaining practical experience in various aspects of organizational management. Eager to apply academic knowledge in real-world scenarios, adapt quickly to new challenges, and contribute effectively to team objectives. Strong analytical, problem-solving, and communication skills combined with a proactive attitude and a willingness to learn and grow within a dynamic and fast-paced environment.`,
    //       temperature: temperature.toFixed(1), // Limit to one decimal place
    //     };
    try {
      const data = await ChatGPTAPI(UserData?.aicredits, payload);
      // dispatch(setSummaryFields(data?.data?.text))
      setloadSpinner(false);
      const aiCreditPayload = {
        credit: 3,
        used: "Summary",
      };
      console.log(data?.data?.text);
      const text = data?.data?.text?.replaceAll("\n", "");
      setsummary(text);
      toast.success("Your 3 AI credits has been used.");
      dispatch(setAiCredits(aiCreditPayload?.credit));
      await CutAiCredits(aiCreditPayload);
    } catch (error) {
      setloadSpinner(false);
      console.log(error);
    }
  };
  const ResumeType = useSelector((item) => item?.resume);

  //disable next btn
  // useEffect(() => {
  //     if (!!summary) {
  //         dispatch(setdisableNext({ type: "summary", value: false }))
  //     } else {
  //         dispatch(setdisableNext({ type: "summary", value: true }))
  //     }
  // }, [summary])
  const saveToRedux = (e) => {
    e.preventDefault();
    if (!!summary) {
      StrongSummary();
      dispatch(setSummaryFields(summary));
      // StepNextBtn()a;
      toast.success("Summary Saved Successfully");
    }
  };

  useEffect(() => {
    if (!!ResumeType?.summary) {
      setsummary(ResumeType?.summary);
    }
  }, []);

  //api call for
  const ResumeRedux = useSelector((item) => item.resume);
  const CoverRedux = useSelector((item) => item.cover);
  const types = useSelector((item) => item.types);
  // const ExperienceString = ResumeRedux?.experiences.length > 0 ? ResumeRedux?.experiences?.reduce((string, item) => string + " ," + item) : ""
  const AiCredit = useSelector((item) => item?.user?.user?.aicredits);

  const StrongSummary = () => {
    let Experience = "";
    Resume?.experiences?.map((item) => (Experience += item?.role + " ,"));
    const ResumeStrongSummary = {
      question: `Evaluate the following resume summary: ${summary}. In the context of the experience level ${Experience}, assign a score between 0 and 100, where 100 represents a perfect, highly relevant, and well-structured summary for this level of experience. Provide only the score.`,
    };
    // if (!ResumeRedux?.isAnalysisData) {
    dispatch(
      GettingResumeStrongSummary({
        AiCredit,
        ResumeStrongSummary,
      })
    );
    // }
    // } else if (CoverRedux?.isAnalysisData === false && types?.doctype === "cover") {
    // dispatch(GettingSummary(CoverletterStrongSummary))
    // }
  };

  const DocType = useSelector((item) => item.types.doctype);
  return (
    <div className="create-resume-main">
      <div className="d-flex justify-content-between">
        <div className="d-flex w-100 justify-content-between align-items-center my-1">
          <h3>Summary</h3>
          <div>
            <button
              onClick={handleResponsivePreviewShow}
              className="align-self-start p-1 responsive-preview-btn d-inline-block d-lg-none"
            >
              Preview
            </button>
          </div>
        </div>
      </div>
      <div className="text-end pe-2 mb-2">
        <small>
          {DocType === "resume"
            ? !!ResumeType?.profession
              ? "Job Specific"
              : "Generic"
            : ""}
        </small>
      </div>
      {/* <p>
                Et pellentesque scelerisque risus blandit quam tempus. Pellentesque
                sodales nec convallis sit ac.
            </p> */}
      <Form className="form-main">
        <div className="add-experience-container">
          <div className="mb-0 add-experience-heading">
            <p className="mb-0 add-experience-heading ">
              {
                <span className="required-label">
                  Write a professional summary
                </span>
              }
            </p>
            <AiButton
              disabled={false}
              SetAi={PostChatGPTData}
              summarycred="3"
            />
          </div>

          {loadSpinner ? (
            <div className="d-flex justify-content-center my-5">
              <Spinner animation="border" variant="secondary" />
            </div>
          ) : (
            <Form.Control
              as="textarea"
              id="summary"
              required
              value={summary}
              onChange={(e) => setsummary(e.target.value)}
              className="px-4"
              placeholder="Leave a comment here"
              style={{ height: "100px" }}
            />
          )}
        </div>
        <button className="save-experience" onClick={saveToRedux}>
          Save{" "}
        </button>

        <GptModal
          PremiumFeature={PremiumFeature}
          handlePremiumFeatureClose={handlePremiumFeatureClose}
        />
        {/* <FloatingLabel
                    className="text-Area-float mb-3"
                    label="WRITE A PROFESSIONAL SUMMARY"
                >
                    <Form.Control
                        id="summary"
                        value={summary}

                        placeholder="Leave a comment here"
                        style={{ height: "100px" }}
                    />
                </FloatingLabel> */}
        {/* <button className="buttonAiSummary"><span>2</span>AI</button> */}
        {/* <button className="save-experience">Save To Experience List</button> */}
      </Form>
    </div>
  );
};
