import React, { useEffect, useRef, useState } from "react";
import logo from "../../../assets/image/logo.svg";
import { useDispatch, useSelector } from "react-redux";
import { SetAnalysis, SetAnalysisResume } from "helper/redux/Slice/ResumeSlice";
import {
  faEnvelope,
  faPhone,
  faMapMarkerAlt,
  faGraduationCap,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const RT8editable = ({ data }) => {
  const [fontSize, setfontSize] = useState(0.7);
  const [recentEducation, setRecentEducation] = useState("");
  const defaultimg = "https://viosa-storage-2.s3.amazonaws.com/profile.png";
  let ResumeData = useSelector((item) => item?.resume);
  // let ResumeData = useSelector((state) => state.resume); // Assuming 'resume' is the correct slice name

  useEffect(() => {
    console.log("Resume data from Redux:", ResumeData);
    if (ResumeData && ResumeData.education && ResumeData.education.length > 0) {
      const sortedEducation = [...ResumeData.education].sort((a, b) => {
        return new Date(b.date + "-01") - new Date(a.date + "-01");
      });
      setRecentEducation(sortedEducation[0]);
    } else {
      console.log("No education data available.");
    }
  }, [ResumeData]); // Dependency on ResumeData to re-run when it updates
  const Style = {
    resumeWrapper: {
      width: "100%",
      padding: "4%",
      minHeight: "100%",
      background: "#fff",
      overflow: "auto",
      containerType: "inline-size",
      wordSpacing: "-1px", // Adjusted for reduced word spacing
      color: "#000",
    },
    resumeHead: {
      fontSize: fontSize,
      color: "#33298b",
    },
    title: {
      fontSize: `${fontSize * 5}cqw`,
      marginBottom: `${fontSize * 1}cqw`,
      color: "#33298b",
      fontWeight: "700",
      // textTransform: "uppercase",
    },
    common: {
      fontSize: `${fontSize * 3}cqw`,
      fontFamily: "'Open Sans-Light', Helvetica",
      marginBottom: `${fontSize * 0.5}cqw`,
      wordBreak: "break-word",
      whiteSpace: "break-spaces",
      textAlign: "justify",
      wordSpacing: "-1px", // Adjusted for reduced word spacing
      color: "#000",
      marginRight: "10px",
      padding: "0px 5px",
      lineHeight: `${fontSize * 5}cqw`,
    },
    profession: {
      color: "#000",
      fontFamily: "'Open Sans-Light', Helvetica",
      fontSize: `${fontSize * 3}cqw`,
      fontWeight: 300,
      textTransform: "uppercase",
      margin: 0,
    },
    li: {
      fontSize: `${fontSize * 3}cqw`,
      marginBottom: `${fontSize * 0}cqw`,
    },
    headings: {
      fontfamily: "'Open Sans', Helvetica",
      fontSize: `${fontSize * 4}cqw`,
      color: "#33298b",
      fontWeight: "700",
      marginBottom: `${fontSize * 1}cqw`,
      marginRight: `15px`,
    },
    subHeadings: {
      fontSize: `${fontSize * 4}cqw`,
      fontWeight: "700",
      marginBottom: `${fontSize * 1}cqw`,
      color: "#000",
      padding: "0px 5px",
    },
    subHeading: {
      fontSize: `${fontSize * 3}cqw`,
      fontWeight: "700",
      marginBottom: `${fontSize * 1}cqw`,
      color: "#000",
      padding: "0px 5px",
    },
    eduheads: {
      fontSize: `${fontSize * 4}cqw`,
      fontWeight: "700",
      marginBottom: `${fontSize * 1}cqw`,
      color: "#000",
      padding: "0px 5px",
      width: "65%",
    },
    marginHR: {
      margin: `${fontSize * 0}cqw 0`,
      color: "#33298b",
      borderTop: "3px solid",
      opacity: 1.25,
    },
    marginHRTop: {
      margin: `${fontSize * 0}cqw 0`,
      color: "#33298b",
      borderTop: "1px solid",
      opacity: 1.25,
    },
    marginUl: {
      margin: `${fontSize * 0}cqw 0`,
      display: "flex",
      listStyletype: "none",
      flexDirection: "row",
      flexWrap: "wrap",
    },
    horizontallist: {
      display: "flex",
      listStyletype: "none",
      margin: 0,
      padding: 0,
    },
    marker: {
      margin: 0,
    },
    ul: {
      margin: 0,
      paddingLeft: "15px",
      fontSize: `${fontSize * 3}cqw`,
    },
    img: {
      paddingBottom: "5px",
      width: "20%",
      objectFit: "contain",
    },
    logo: {
      width: "20%",
      objectFit: "contain",
      paddingBottom: "5px",
    },
    // Existing styles here...
    table: {
      width: "100%",
      borderCollapse: "collapse",
      margin: "5px 0px",
      border: "2px solid #ccc", // Border around the full table
    },
    tableRow: {
      borderBottom: "1px solid #ccc",
    },
    tableCell: {
      padding: "5px",
      fontSize: `${fontSize * 3}cqw`,
      textAlign: "left",
      border: "1px solid #ccc", // Border for each cell
    },
    tableHeader: {
      backgroundColor: "#f4f4f4",
      fontWeight: "bold",
      border: "1px solid #ccc", // Optionally add border to header cells too
    },
    section: {
      paddingBottom: "5px",
    },
    // Existing styles
    skillsList: {
      display: "flex",
      alignItems: "center", // Ensure items are aligned properly
      flexWrap: "wrap",
      listStyleType: "none", // Removes bullet points
      padding: "5px 0px 0px 5px",
      margin: 0,
      fontSize: "20px", // Adjust font size as needed
    },
    skillItem: {
      marginRight: "5px", // Adjust spacing around bars
    },
    align: {
      marginTop: "-2px",
    },
    common2: {
      fontSize: `${fontSize * 3}cqw`,
      fontFamily: "'Open Sans-Light', Helvetica",
      marginBottom: `${fontSize * 0.5}cqw`,
      wordBreak: "break-word",
      whiteSpace: "break-spaces",
      textAlign: "justify",
      wordSpacing: "-1px", // Adjusted for reduced word spacing
      color: "#000",
      // marginRight: "10px",
      padding: "0px 5px 0px 0px",
      lineHeight: `${fontSize * 5}cqw`,
    },
  };
  console.log("resumedata", ResumeData);
  if (!!data) {
    ResumeData = data;
  }

  const monthYear = (date) => {
    if (!date) {
      // Handle the case when date is undefined
      return "Unknown Date";
    }
    const [year, month, day] = date.split("-");
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    if (day) {
      // Format: YYYY-MM-DD
      return `${monthNames[parseInt(month, 10) - 1]}-${year}`;
    } else if (date.length === 7) {
      let month = date.slice(5, 7);
      let year = date.slice(0, 4);
      switch (month) {
        case "1":
          return `Jan-${year}`;
        case "2":
          return `Feb-${year}`;
        case "3":
          return `March-${year}`;
        case "4":
          return `April-${year}`;
        case "5":
          return `May-${year}`;
        case "6":
          return `June-${year}`;
        case "7":
          return `July-${year}`;
        case "8":
          return `Aug-${year}`;
        case "9":
          return `Sep-${year}`;
        case "10":
          return `Oct-${year}`;
        case "11":
          return `Nov-${year}`;
        case "12":
          return `Dec-${year}`;
        // break;
        default:
          // return date;
          return `${monthNames[parseInt(month, 10) - 1]}-${year}`;
        // break;
      }
    } else {
      let day = date.slice(8, 10);
      let month = date.slice(5, 7);
      let year = date.slice(0, 4);
      switch (month) {
        case "1":
          return `${day}-Jan-${year}`;
        case "2":
          return `${day}-Feb-${year}`;
        case "3":
          return `${day}-March-${year}`;
        case "4":
          return `${day}-April-${year}`;
        case "5":
          return `${day}-May-${year}`;
        case "6":
          return `${day}-June-${year}`;
        case "7":
          return `${day}-July-${year}`;
        case "8":
          return `${day}-Aug-${year}`;
        case "9":
          return `${day}-Sep-${year}`;
        case "10":
          return `${day}-Oct-${year}`;
        case "11":
          return `${day}-Nov-${year}`;
        case "12":
          return `${day}-Dec-${year}`;
        // break;
        default:
          return date;
        // break;
      }
    }
  };
  useEffect(() => {
    if (ResumeData && ResumeData.education && ResumeData.education.length > 0) {
      const sortedEducation = [...ResumeData.education].sort(
        (a, b) => new Date(b.date) - new Date(a.date)
      );
      console.log("Sorted education entries:", sortedEducation);
    }
  }, [ResumeData]);

  return (
    <>
      <div style={Style.resumeWrapper}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            paddingBottom: "5px",
          }}
        >
          <div>
            <img
              src="https://viosainterviewvideos.s3.ap-south-1.amazonaws.com/interviewvideos/Ashoka+logo.png"
              alt="profile"
              style={Style.logo}
            />
            <h1 style={Style.title}>
              {`${ResumeData?.contact?.title} ${ResumeData?.contact?.firstName} ${ResumeData?.contact?.lastName}`}{" "}
            </h1>
            <div className="resume-head">
              {recentEducation && (
                <h4 style={Style.common}>
                  <FontAwesomeIcon icon={faGraduationCap} />{" "}
                  {recentEducation.degree}
                  {recentEducation.major &&
                    ` [Major: ${recentEducation.major}]`}
                  {recentEducation.major && recentEducation.minor && ", "}
                  {recentEducation.minor &&
                    ` [Minor: ${recentEducation.minor}]`}
                </h4>
              )}
              <h4 style={Style.common}>
                <FontAwesomeIcon icon={faEnvelope} />{" "}
                {ResumeData?.contact?.email}
              </h4>
              <h4 style={Style.common}>
                <FontAwesomeIcon icon={faPhone} /> {ResumeData?.contact?.number}
              </h4>
              <h4 style={Style.common}>
                <FontAwesomeIcon icon={faMapMarkerAlt} />{" "}
                {!!ResumeData?.contact?.address
                  ? ResumeData?.contact?.address
                  : `${ResumeData?.contact?.city} ${ResumeData?.contact?.country}`}
              </h4>
            </div>
          </div>
          <img
            src={ResumeData?.contact?.picture || defaultimg}
            alt="profile"
            style={Style.img}
          />
        </div>

        <div>
          <div>
            <div>
              {!!ResumeData?.summary && (
                <div class="yui-gf mb-2">
                  <h2 style={Style.headings}>Professional Summary</h2>
                  <div
                    style={{
                      flex: 1,
                      backgroundColor: "#767675",
                      height: "3px",
                    }}
                  />
                  <div class="yui-u">
                    <p style={Style.common}>{ResumeData?.summary}</p>
                  </div>
                </div>
              )}

              {ResumeData?.education?.length > 0 && (
                <>
                  <div class="yui-gf last mb-2">
                    <h2 style={Style.headings}>Education</h2>
                    <div
                      style={{
                        flex: 1,
                        backgroundColor: "#767675",
                        height: "3px",
                      }}
                    />
                    <table style={Style.table}>
                      {/* <thead>
                        <tr style={Style.tableHeader}>
                          <th style={Style.tableCell}>Degree</th>
                          <th style={Style.tableCell}>Institute</th>
                          <th style={Style.tableCell}>Year</th>
                          <th style={Style.tableCell}>Percentage/Grade</th>
                        </tr>
                      </thead> */}
                      <tbody>
                        {[...ResumeData?.education]
                          ?.sort((a, b) => {
                            // Convert date strings to Date objects for comparison
                            const dateA = new Date(a.date);
                            const dateB = new Date(b.date);
                            // Sort in descending order (latest first)
                            return dateB - dateA;
                          })
                          .map((edu, index) => (
                            <tr key={index} style={Style.tableRow}>
                              <td style={Style.tableCell}>
                                {edu.degree}{" "}
                                {edu.specialization
                                  ? `- ${edu.specialization}`
                                  : ""}
                              </td>
                              <td style={Style.tableCell}>
                                {edu.institute}, {edu.location}
                              </td>
                              <td style={Style.tableCell}>
                                {" "}
                                {monthYear(edu?.date)}{" "}
                              </td>
                              <td style={Style.tableCell}>
                                {edu.percentage
                                  ? `${edu.percentage}%`
                                  : edu.gpa}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </>
              )}

              {ResumeData?.skills?.length > 0 && (
                <>
                  <div class="yui-gf  mb-2">
                    <h2 style={Style.headings} className="mb-0">
                      Skills
                    </h2>
                    <div
                      style={{
                        flex: 1,
                        backgroundColor: "#767675",
                        height: "3px",
                      }}
                    />
                    {/* <div class="yui-u d-flex">
                      <div style={Style.marginUl}>
                        {ResumeData?.skills?.map((item, index) => {
                          return (
                              <span key={index} style={Style?.align}>
                                {item?.name}
                                {index < ResumeData?.skills?.length - 1 ? ' | ' : ''}
                              </span>
                          );
                        })}
                      </div>
                    </div> */}

                    {ResumeData.skills && ResumeData.skills.length > 0 && (
                      <ul style={Style.skillsList}>
                        {ResumeData.skills.map((skill, index) => (
                          <span key={index} style={Style?.common2}>
                            {skill?.name}
                            {index < ResumeData?.skills?.length - 1
                              ? "  | "
                              : ""}
                          </span>
                        ))}
                      </ul>
                    )}
                  </div>
                </>
              )}

              {ResumeData?.certification?.length > 0 && (
                <>
                  <div class="yui-gf last mb-2">
                    <h2 style={Style.headings}>Certificates/Workshops</h2>
                    <div
                      style={{
                        flex: 1,
                        backgroundColor: "#767675",
                        height: "3px",
                      }}
                    />
                    <div>
                      {/* {ResumeData?.certification?.map((item) => { */}
                      {[...ResumeData.certification]
                        ?.filter((item) => item && item.date) // Filter out null or undefined items and items without a valid date
                        .sort((a, b) => new Date(b.date) - new Date(a.date))
                        .map((item, index) => {
                          return (
                            <>
                              <div class="yui-u d-flex justify-content-between">
                                <h2 style={Style.eduheads}>{item?.name}</h2>
                                <p style={Style.common}>
                                  {monthYear(item?.date)}
                                </p>
                              </div>
                              <div class="yui-u">
                                <p style={Style.common}>{item?.org}</p>
                              </div>
                            </>
                          );
                        })}
                    </div>
                  </div>
                </>
              )}

              {ResumeData?.projects?.length > 0 && (
                <>
                  <div class="yui-gf mb-2">
                    <h2 style={Style.headings}>Projects</h2>
                    <div
                      style={{
                        flex: 1,
                        backgroundColor: "#767675",
                        height: "3px",
                      }}
                    />
                    <div class="yui-u ">
                      {/* {ResumeData?.projects?.map((item) => { */}
                      {[...ResumeData?.projects]
                        ?.sort((a, b) => {
                          // Convert date strings to Date objects for comparison
                          const dateA = new Date(a.start);
                          const dateB = new Date(b.start);
                          // Sort in descending order (latest first)
                          return dateB - dateA;
                        })
                        .map((item) => {
                          return (
                            <div class="job">
                              <div className="d-flex gap-4 justify-content-between">
                                <h2 style={Style.subHeadings}>
                                  {item?.title}{" "}
                                </h2>
                                <p style={Style.common}>
                                  {monthYear(item?.start)}
                                </p>
                              </div>
                              <p style={Style.common}>{item?.description}</p>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                </>
              )}

              {ResumeData?.researchPapers?.length > 0 && (
                <>
                  <div class="yui-gf mb-2">
                    <h2 style={Style.headings}>Research Papers</h2>
                    <div
                      style={{
                        flex: 1,
                        backgroundColor: "#767675",
                        height: "3px",
                      }}
                    />
                    <div class="yui-u ">
                      {[...ResumeData?.researchPapers]
                        ?.sort((a, b) => {
                          // Convert date strings to Date objects for comparison
                          const dateA = new Date(a.start);
                          const dateB = new Date(b.start);
                          // Sort in descending order (latest first)
                          return dateB - dateA;
                        })
                        .map((item) => {
                          return (
                            <div class="job">
                              <div className="d-flex gap-4 justify-content-between">
                                <h2 style={Style.subHeadings}>
                                  {item?.title}{" "}
                                </h2>
                                <p style={Style.common}>
                                  {monthYear(item?.start)}
                                </p>
                              </div>
                              <p style={Style.common}>{item?.description}</p>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                </>
              )}

              {ResumeData?.experiences?.length > 0 && (
                <>
                  <div class="yui-gf mb-2">
                    <h2 style={Style.headings}>Experience</h2>
                    <div
                      style={{
                        flex: 1,
                        backgroundColor: "#767675",
                        height: "3px",
                      }}
                    />
                    <div class="yui-u ">
                      {/* {ResumeData?.experiences?.map((item) => { */}
                      {[...ResumeData.experiences]
                        ?.sort((a, b) => new Date(b.start) - new Date(a.start))
                        .map((item, index) => {
                          return (
                            <div class="job">
                              <div className="d-flex justify-content-between">
                                <p style={Style.eduheads}>{item?.role}</p>
                                <p style={Style.common}>
                                  {monthYear(item?.start)} -{" "}
                                  {item?.isWorking
                                    ? "Present"
                                    : monthYear(item?.end)}
                                </p>
                              </div>
                              <div className="d-flex justify-content-between">
                                <h2 style={Style?.subHeading}>
                                  {item?.company} ,{" "}
                                  <small>{item?.location}</small>
                                </h2>
                                <p style={{ ...Style.common, ...Style.align }}>
                                  {item.jobtype}
                                </p>
                              </div>

                              <p style={Style.common}>{item?.description}</p>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                </>
              )}

              {ResumeData?.achievements?.length > 0 && (
                <>
                  <div class="yui-gf last mb-2">
                    <h2 style={Style.headings}>Achievements</h2>
                    <div
                      style={{
                        flex: 1,
                        backgroundColor: "#767675",
                        height: "3px",
                      }}
                    />
                    <div>
                      {/* {ResumeData?.achievements?.map((item) => { */}
                      {[...ResumeData.achievements]
                        ?.sort((a, b) => new Date(b.date) - new Date(a.date))
                        .map((item, index) => {
                          return (
                            <>
                              <div class="yui-u d-flex justify-content-between">
                                <div>
                                  <h2 style={Style.subHeadings}>
                                    {item?.title}{" "}
                                  </h2>
                                </div>
                                <div>
                                  <p style={Style.common}>
                                    {monthYear(item?.date)}
                                  </p>
                                </div>
                              </div>
                              <div>
                                <p style={Style.common}>{item?.description}</p>
                              </div>
                            </>
                          );
                        })}
                    </div>
                  </div>
                </>
              )}

              {ResumeData?.involvement?.length > 0 && (
                <>
                  <div class="yui-gf last mb-2">
                    <h2 style={Style.headings}>Voluntary</h2>
                    <div
                      style={{
                        flex: 1,
                        backgroundColor: "#767675",
                        height: "3px",
                      }}
                    />
                    <div>
                      {/* {ResumeData?.involvement?.map((item) => { */}
                      {[...ResumeData.involvement]
                        ?.sort((a, b) => new Date(b.start) - new Date(a.start))
                        .map((item, index) => {
                          return (
                            <>
                              <div class="yui-u d-flex justify-content-between">
                                <div>
                                  <h2 style={Style.subHeadings}>
                                    {item?.role}{" "}
                                  </h2>
                                  <h2 style={Style.common}>
                                    {item?.company} {item?.location}
                                  </h2>
                                </div>
                                <div>
                                  <p style={Style.common}>
                                    {monthYear(item?.start)} -{" "}
                                    {item?.isWorking
                                      ? "Present"
                                      : monthYear(item?.end)}
                                  </p>
                                </div>
                              </div>
                              <div>
                                <p style={Style.common}>{item?.description}</p>
                              </div>
                            </>
                          );
                        })}
                    </div>
                  </div>
                </>
              )}
              {ResumeData?.hobby?.length > 0 && (
                <>
                  <div class="yui-gf mb-2">
                    <h2 style={Style.headings} className="mb-0">
                      Hobby
                    </h2>
                    <div
                      style={{
                        flex: 1,
                        backgroundColor: "#767675",
                        height: "3px",
                      }}
                    />
                    <div class="yui-u d-flex">
                      <div style={Style.marginUl}>
                        {ResumeData?.hobby?.map((item, index) => {
                          return (
                            <span key={index} style={Style.common2}>
                              {item.name}
                              {index < ResumeData.hobby.length - 1 ? " | " : ""}
                            </span>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </>
              )}
              {ResumeData?.languagesKnown?.length > 0 && (
                <>
                  <div class="yui-gf mb-2">
                    <h2 style={Style.headings} className="mb-0">
                      Languages Known
                    </h2>
                    <div
                      style={{
                        flex: 1,
                        backgroundColor: "#767675",
                        height: "3px",
                      }}
                    />
                    <div class="yui-u d-flex">
                      <div style={Style.marginUl}>
                        {ResumeData?.languagesKnown?.map((item, index) => {
                          return (
                            <span key={index} style={Style.common2}>
                              {item.name}
                              {index < ResumeData.languagesKnown.length - 1
                                ? " | "
                                : ""}
                            </span>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </>
              )}
              {ResumeData?.links?.length > 0 && (
                <>
                  <div class="yui-gf mb-2">
                    <h2 style={Style.headings} className="mb-0">
                      Links
                    </h2>
                    <div
                      style={{
                        flex: 1,
                        backgroundColor: "#767675",
                        height: "3px",
                      }}
                    />
                    <div class="yui-u d-flex">
                      <div>
                        {ResumeData?.links?.map((item, index) => {
                          return (
                            <ul style={Style?.ul}>
                              <li
                                key={index}
                                style={{ ...Style.ul, listStyleType: "disc" }}
                              >
                                {item?.name}
                                {index < ResumeData?.links?.length - 1 && ""}
                              </li>
                            </ul>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RT8editable;
